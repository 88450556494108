import * as React from 'react'
import { Create, useTranslate } from 'react-admin'
import Form from '../form'

export const PostCreate = (props: any): JSX.Element => {
  const translate = useTranslate()
  return (
    <Create {...props} title={translate('resources.parkings.create.title')}>
      <Form />
    </Create>
  )
}

export default PostCreate
