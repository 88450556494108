import * as React from 'react'
import { Filter, TextInput } from 'react-admin'

const Filters: React.FC = (props: any): JSX.Element => {
  return (
    <Filter {...props}>
      <TextInput source='email' alwaysOn />
    </Filter>
  )
}

export default Filters
