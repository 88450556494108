import polyglotI18nProvider from 'ra-i18n-polyglot'
import reactAdminMessagesES from '@blackbox-vision/ra-language-spanish'
import es from './es'

const messages: any = {
  es: { ...reactAdminMessagesES, ...es },
}
const i18nProvider = polyglotI18nProvider((locale: string) => messages[locale], 'es', { allowMissing: true })

export default i18nProvider
