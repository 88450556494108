import * as React from 'react'
import { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core'
import Link from '@material-ui/core/Link'

interface Props {
  redirectTo?: string
}

interface FormData {
  username: string | undefined
  password: string | undefined
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}: any) => {
  return <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
}

const LoginForm: FunctionComponent<Props> = props => {
  const { redirectTo } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles(props)

  const validate = (values: FormData) => {
    const errors: FormData = { username: undefined, password: undefined }

    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values: any) => {
    setLoading(true)
    login(values, redirectTo)
      .catch(error => {
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id='username'
                name='username'
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id='password'
                name='password'
                component={Input}
                label={translate('ra.auth.password')}
                type='password'
                disabled={loading}
                autoComplete='current-password'
              />
              <div className={classes.input}>
                <Link href='/forgot' variant='body2'>
                  {translate('login.forgotPass')}
                </Link>
              </div>
            </div>
          </div>
          <CardActions>
            <Button variant='contained' type='submit' color='primary' disabled={loading} className={classes.button}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

export default LoginForm
