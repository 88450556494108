import restProvider from './restProvider'
import { fetchUtils } from 'react-admin'

const fetchJson = (url: string, options: any = {}) => {
  options.headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  })
  return fetchUtils.fetchJson(url, options).catch((error: any) => {
    if (error.status === 401) {
      localStorage.clear()
    }
    if (!localStorage.getItem('token')) window.location.href = '/login'
    return Promise.reject(error)
  })
}

export default restProvider(process.env.REACT_APP_API_PATH + '/api', fetchJson)
