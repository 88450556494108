import * as React from 'react'
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin'

export const PostShow = (props: any): JSX.Element => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='tagCode' />
        <TextField source='description' />
        <BooleanField source='mandatory' />
      </SimpleShowLayout>
    </Show>
  )
}

export default PostShow
