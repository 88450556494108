import * as React from 'react'
import { Filter, TextInput } from 'react-admin'

const Filters: React.FC = (props: any): JSX.Element => {
  return (
    <Filter {...props}>
      <TextInput source='id' alwaysOn />
      <TextInput source='tagCode' alwaysOn />
      <TextInput source='description' alwaysOn />
    </Filter>
  )
}

export default Filters
