import React, { useState, useEffect } from 'react'
import {
  SimpleForm,
  TextInput,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  AutocompleteArrayInput,
  required,
  NumberInput,
  RadioButtonGroupInput,
  NullableBooleanInput,
  maxValue,
  minValue,
  number,
  FormDataConsumer,
  ReferenceArrayInput,
  BooleanInput,
  DateInput,
} from 'react-admin'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Box, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import { ImageInput, TimeInput } from '../../../molecules'
import { endValidation, quarterMinValidation, dateValidation } from './validation'
import _get from 'lodash/get'
import { useForm } from 'react-final-form'

const TimeRange = (props: any): JSX.Element => {
  const { source } = props
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()

  const DAYS_CHOICES = [
    { id: 1, name: translate(`weeks.${1}`) },
    { id: 2, name: translate(`weeks.${2}`) },
    { id: 3, name: translate(`weeks.${3}`) },
    { id: 4, name: translate(`weeks.${4}`) },
    { id: 5, name: translate(`weeks.${5}`) },
    { id: 6, name: translate(`weeks.${6}`) },
    { id: 0, name: translate(`weeks.${0}`) },
  ]

  const daysIds = DAYS_CHOICES?.map(day => day.id)

  return (
    <div className={classes.timeRangesForm}>
      <FormDataConsumer>
        {({ formData }: any) => {
          const timeRange = _get(formData, source)
          return (
            <>
              <Box display='flex'>
                <Box flex={1} p={1}>
                  <TimeInput
                    {...props}
                    fullWidth
                    label='resources.campaigns.fields.start'
                    source={source + '.start'}
                    type='time'
                    alwaysOn
                    inputProps={{
                      step: 900,
                    }}
                    validate={[required(), quarterMinValidation(translate('resources.campaigns.errors.quarterError'))]}
                  />
                </Box>
                <Box flex={1} p={1}>
                  <TimeInput
                    {...props}
                    fullWidth
                    label='resources.campaigns.fields.end'
                    source={source + '.end'}
                    type='time'
                    alwaysOn
                    inputProps={{
                      step: 900,
                    }}
                    validate={[
                      required(),
                      quarterMinValidation(translate('resources.campaigns.errors.quarterError')),
                      endValidation(source + '.start', translate('resources.campaigns.errors.endIsBeforeStart')),
                    ]}
                  />
                </Box>
              </Box>
              {(timeRange?.weekDay?.[0] === undefined || timeRange?.weekDay?.length < DAYS_CHOICES.length) && (
                <Box display='flex'>
                  <Box flex={2} p={1}>
                    <BooleanInput
                      source={source + '.checkAll'}
                      label='resources.campaigns.fields.checkAll'
                      onChange={(value: boolean) => {
                        if (value === true) {
                          form.change(`${source}.checkAll`, false)
                          form.change(`${source}.weekDay`, daysIds)
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box display='flex'>
                <Box flex={2} p={1}>
                  <SelectArrayInput
                    {...props}
                    fullWidth
                    label='resources.campaigns.fields.weekDay'
                    source={source + '.weekDay'}
                    choices={DAYS_CHOICES}
                  />
                </Box>
              </Box>
            </>
          )
        }}
      </FormDataConsumer>
    </div>
  )
}

export const PostCreate = (props: any): JSX.Element => {
  const translate = useTranslate()
  const classes = useStyles()

  const [width, setWidth] = useState(1)
  const [height, setHeight] = useState(1)

  const [crop, setCrop] = useState({ x: 0, y: 0, width, height })
  const [secondCrop, setSecondCrop] = useState({ x: 0, y: 0, width, height })
  const [thirdCrop, setThirdCrop] = useState({ x: 0, y: 0, width, height })

  const [cropImage, setCropImage] = useState<HTMLImageElement>()
  const [secondCropImage, setSecondCropImage] = useState<HTMLImageElement>()
  const [thirdCropImage, setThirdCropImage] = useState<HTMLImageElement>()

  useEffect(() => {
    setCrop({
      x: 0,
      y: 0,
      width,
      height,
    })
    setSecondCrop({
      x: 0,
      y: 0,
      width,
      height,
    })
    setThirdCrop({
      x: 0,
      y: 0,
      width,
      height,
    })
  }, [width, height])

  const types = [
    { id: 1, name: translate('resources.campaigns.fields.image') },
    { id: 2, name: translate('resources.campaigns.fields.video') },
  ]

  function getCroppedImg(image: any, crop: any) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    if (crop?.width > 0 && crop?.height > 0) {
      const base64Image = canvas.toDataURL('image/jpeg')
      return base64Image
    }

    return
  }

  const placeholderView = (formData: any, place: string) => {
    if (place === 'first' && props.record.url && props.record.type === 1 && formData.type === 1)
      return <img src={props.record.url} alt='creative' />
    if (place === 'second' && props.record.secondUrl && props.record.type === 1 && formData.type === 1)
      return <img src={props.record.secondUrl} alt='creative' />
    if (place === 'third' && props.record.thirdUrl && props.record.type === 1 && formData.type === 1)
      return <img src={props.record.thirdUrl} alt='creative' />
    if (place === 'first' && props.record.url && props.record.type === 2 && formData.type === 2)
      return <video className={classes.video} src={props.record.url} controls width='300'></video>
    if (place === 'second' && props.record.secondUrl && props.record.type === 2 && formData.type === 2)
      return <video className={classes.video} src={props.record.secondUrl} controls width='300'></video>
    if (place === 'third' && props.record.thirdUrl && props.record.type === 2 && formData.type === 2)
      return <video className={classes.video} src={props.record.thirdUrl} controls width='300'></video>
  }

  const imageTypes = 'image/jpeg, image/jpg, image/png, image/gif'
  const videoTypes = 'video/mp4, video/mov, video/webm'

  return (
    <SimpleForm {...props} redirect='list'>
      <FormDataConsumer>
        {({ formData }: any) => {
          const getMimeType = (file: any) => file?.substring(file.indexOf('/') + 1, file.indexOf(';'))

          const mimeType = getMimeType(formData?.file)
          const secondMimeType = getMimeType(formData?.secondFile)
          const thirdMimeType = getMimeType(formData?.thirdFile)

          return (
            <>
              <Box display='flex'>
                <Box p={1} flex={1}>
                  <ImageInput
                    source='file'
                    placeholder={placeholderView(formData, 'first')}
                    label={
                      formData.type === 1 ? (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.firstImage')}
                        </Typography>
                      ) : (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.firstVideo')}
                        </Typography>
                      )
                    }
                    accept={formData.type === 1 ? imageTypes : videoTypes}
                    multiple={false}
                  />
                </Box>
                <Box p={1} flex={1}>
                  <ImageInput
                    source='secondFile'
                    placeholder={placeholderView(formData, 'second')}
                    label={
                      formData.type === 1 ? (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.secondImage')}
                        </Typography>
                      ) : (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.secondVideo')}
                        </Typography>
                      )
                    }
                    accept={formData.type === 1 ? imageTypes : videoTypes}
                    multiple={false}
                  />
                </Box>
                <Box p={1} flex={1}>
                  <ImageInput
                    source='thirdFile'
                    placeholder={placeholderView(formData, 'third')}
                    label={
                      formData.type === 1 ? (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.thirdImage')}
                        </Typography>
                      ) : (
                        <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                          {translate('resources.campaigns.fields.thirdVideo')}
                        </Typography>
                      )
                    }
                    accept={formData.type === 1 ? imageTypes : videoTypes}
                    multiple={false}
                  />
                </Box>
                <Box p={1} flex={1}>
                  <Box>
                    <RadioButtonGroupInput
                      source='type'
                      label='resources.campaigns.fields.type'
                      defaultValue={1}
                      choices={types}
                      validate={[required()]}
                    />
                  </Box>
                  <Box>
                    <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                      {translate('resources.campaigns.cropTitle')}
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <TextField
                      type='number'
                      variant='filled'
                      label={translate('resources.campaigns.cropWidth')}
                      onChange={async event => {
                        const inputWidth = parseInt(event.currentTarget.value, 10)
                        setWidth(inputWidth)
                        const inputCrop = {
                          x: 0,
                          y: 0,
                          width: inputWidth,
                          height,
                        }
                        if (formData.file) {
                          const croppedImage = await getCroppedImg(cropImage, inputCrop)
                          formData.fileCropped = croppedImage
                        } else if (formData.secondFile) {
                          const croppedImage = await getCroppedImg(secondCropImage, inputCrop)
                          formData.secondFileCropped = croppedImage
                        } else if (formData.thirdFile) {
                          const croppedImage = await getCroppedImg(thirdCropImage, inputCrop)
                          formData.thirdFileCropped = croppedImage
                        }
                      }}
                      fullWidth
                      disabled={
                        formData.type === 2 || mimeType === 'gif' || secondMimeType === 'gif' || thirdMimeType === 'gif'
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box>
                    <TextField
                      type='number'
                      variant='filled'
                      label={translate('resources.campaigns.cropHeight')}
                      onChange={async event => {
                        const inputHeight = parseInt(event.currentTarget.value, 10)
                        setHeight(inputHeight)
                        const inputCrop = {
                          x: 0,
                          y: 0,
                          width,
                          height: inputHeight,
                        }
                        if (formData.file) {
                          const croppedImage = await getCroppedImg(cropImage, inputCrop)
                          formData.fileCropped = croppedImage
                        } else if (formData.secondFile) {
                          const croppedImage = await getCroppedImg(secondCropImage, inputCrop)
                          formData.secondFileCropped = croppedImage
                        } else if (formData.thirdFile) {
                          const croppedImage = await getCroppedImg(thirdCropImage, inputCrop)
                          formData.thirdFileCropped = croppedImage
                        }
                      }}
                      fullWidth
                      disabled={
                        formData.type === 2 || mimeType === 'gif' || secondMimeType === 'gif' || thirdMimeType === 'gif'
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Box>
              </Box>

              <Box display='flex' flexDirection='column'>
                <Box p={1}>
                  {formData?.file && formData?.type === 1 && mimeType !== 'gif' && (
                    <ReactCrop
                      src={formData?.file}
                      crop={crop}
                      onComplete={async (newCrop: any) => {
                        if (cropImage && newCrop.width && newCrop.height) {
                          const croppedImage = await getCroppedImg(cropImage, newCrop)
                          formData.fileCropped = croppedImage
                          setCrop(newCrop)
                        }
                      }}
                      onChange={(newCrop: any) => {
                        setCrop(newCrop)
                      }}
                      onImageLoaded={image => {
                        setCropImage(image)
                      }}
                      locked
                    />
                  )}
                </Box>
                <Box p={1}>
                  {formData?.secondFile && formData?.type === 1 && secondMimeType !== 'gif' && (
                    <ReactCrop
                      src={formData?.secondFile}
                      crop={secondCrop}
                      onComplete={async (newCrop: any) => {
                        if (secondCropImage && newCrop.width && newCrop.height) {
                          const croppedImage = await getCroppedImg(secondCropImage, newCrop)
                          formData.secondFileCropped = croppedImage
                          setSecondCropImage(newCrop)
                        }
                      }}
                      onChange={(newCrop: any) => {
                        setSecondCrop(newCrop)
                      }}
                      onImageLoaded={image => {
                        setSecondCropImage(image)
                      }}
                      locked
                    />
                  )}
                </Box>
                <Box p={1}>
                  {formData?.thirdFile && formData?.type === 1 && thirdMimeType !== 'gif' && (
                    <ReactCrop
                      src={formData?.thirdFile}
                      crop={thirdCrop}
                      onComplete={async (newCrop: any) => {
                        if (thirdCropImage && newCrop.width && newCrop.height) {
                          const croppedImage = await getCroppedImg(thirdCropImage, newCrop)
                          formData.thirdFileCropped = croppedImage
                          setThirdCropImage(newCrop)
                        }
                      }}
                      onChange={(newCrop: any) => {
                        setThirdCrop(newCrop)
                      }}
                      onImageLoaded={image => {
                        setThirdCropImage(image)
                      }}
                      locked
                    />
                  )}
                </Box>
              </Box>
            </>
          )
        }}
      </FormDataConsumer>
      <Box display='flex' className={classes.root}>
        <Box p={1} flex={1}>
          <TextInput source='name' label='resources.campaigns.fields.title' validate={[required()]} fullWidth />
        </Box>
        <Box p={1} flex={1}>
          <TextInput
            source='campaignCode'
            label='resources.campaigns.fields.campaignCode'
            validate={[required()]}
            fullWidth
          />
        </Box>
        <Box p={1} flex={1}>
          <TextInput source='warningMail' label='resources.campaigns.fields.email' validate={[required()]} fullWidth />
        </Box>
        <Box p={1} flex={1}>
          <NullableBooleanInput
            source='active'
            label='resources.campaigns.fields.active'
            fullWidth
            validate={[required()]}
          />
        </Box>
      </Box>
      <Box display='flex' className={classes.root}>
        <Box p={1} flex={1}>
          <NumberInput
            source='duration'
            label='resources.campaigns.fields.duration'
            validate={[required(), number(), maxValue(59), minValue(5)]}
            max={59}
            min={5}
            fullWidth
          />
        </Box>
        <Box p={1} flex={1}>
          <DateInput
            source='start'
            label='resources.campaigns.fields.startDate'
            options={{
              format: 'YYYY-MM-DD',
            }}
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box p={1} flex={1}>
          <DateInput
            source='end'
            label='resources.campaigns.fields.endDate'
            options={{
              format: 'YYYY-MM-DD',
            }}
            fullWidth
            validate={[
              required(),
              dateValidation('start', translate('resources.campaigns.errors.endIsBeforeStartDate')),
            ]}
          />
        </Box>
      </Box>
      <Box p={1} flex={1} className={classes.root}>
        <Box pr={2}>
          <ArrayInput source='timeRanges' validate={[required()]} label='resources.campaigns.fields.timeRanges'>
            <SimpleFormIterator>
              <TimeRange />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </Box>
      <Box p={1} flex={1} className={classes.root}>
        <Box pr={2}>
          <ReferenceArrayInput
            source='tags'
            reference='parkingTags'
            label={translate('resources.campaigns.fields.tags')}
            filterToQuery={(searchText: string) => ({ tagCode: searchText })}
            sort={{ field: 'tagCode', order: 'ASC' }}
            fullWidth
          >
            <AutocompleteArrayInput optionText='tagCode' />
          </ReferenceArrayInput>
        </Box>
      </Box>
    </SimpleForm>
  )
}

export default PostCreate
