import * as React from 'react'
import {
  List,
  downloadCSV,
  Datagrid,
  SingleFieldList,
  TextField,
  ArrayField,
  useTranslate,
  EditButton,
  ShowButton,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { makeStyles } from '@material-ui/core/styles'
import Filters from './filters'
import { ColoredChipField } from '../../../atoms'
import { Empty } from '../../../organisms'
import { roles } from '../../../../assets/utils'
import _find from 'lodash/find'
import _split from 'lodash/split'

const useStyles = makeStyles({
  singleList: {
    margin: '0px',
    pointerEvents: 'none',
  },
})

const PostList = (props: any): JSX.Element => {
  const { permissions } = props
  const permissionsList = _split(permissions, ',')
  const isAdmin = _find(permissionsList, o => o === roles.admin)
  const translate = useTranslate()
  const classes = useStyles()

  const exporter = (posts: any) => {
    const postsForExport = posts.map((post: any) => {
      return post
    })
    jsonExport(
      postsForExport,
      {
        headers: [
          'id',
          'customId',
          'parkingCode',
          'description',
          'tags.id',
          'tags.tagCode',
          'tags.description',
          'tags.mandatory',
        ],
        rename: [
          translate('resources.parkings.fields.id'),
          translate('resources.parkings.fields.customId'),
          translate('resources.parkings.fields.parkingCode'),
          translate('resources.parkings.fields.description'),
          translate('resources.parkingTags.fields.id'),
          translate('resources.parkingTags.fields.tagCode'),
          translate('resources.parkingTags.fields.description'),
          translate('resources.parkingTags.fields.mandatory'),
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'parkings')
      }
    )
  }

  return (
    <List
      {...props}
      filters={<Filters />}
      title={translate('resources.parkings.list.title')}
      empty={<Empty />}
      exporter={exporter}
    >
      <Datagrid>
        <TextField source='customId' />
        <TextField source='parkingCode' />
        <TextField source='description' />
        <ArrayField source='tags'>
          <SingleFieldList className={classes.singleList}>
            <ColoredChipField source='tagCode' clickable={false} />
          </SingleFieldList>
        </ArrayField>
        {isAdmin && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PostList
