import React, { useEffect, useState } from 'react'
import { Show, SimpleShowLayout, TextField, SingleFieldList, ReferenceArrayField, useTranslate } from 'react-admin'
import * as Styles from './styles'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ColoredChipField } from '../../../atoms'
import moment from 'moment'
import { getCampaigns, getParkings } from '../../../../api'

const useStyles = makeStyles({
  tags: {
    marginBottom: '0',
  },
  container: {
    padding: '10px',
    marginBottom: '10px',
  },
})

export const PostShow = (props: any): JSX.Element => {
  const classes = useStyles()
  const [campaigns, setCampaigns] = useState([])
  const translate = useTranslate()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function fetchCampaigns(id: number) {
      try {
        const responseParking = await getParkings(id)
        const response = await getCampaigns(responseParking?.data?.customId)
        setCampaigns(response.data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchCampaigns(props?.id)
  }, [])
  /* eslint-disable */

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='customId' className={classes.container} />
        <TextField source='parkingCode' className={classes.container} />
        <TextField source='description' className={classes.container} />
        <ReferenceArrayField className={(classes.tags, classes.container)} reference='parkingTags' source='tags'>
          <SingleFieldList>
            <ColoredChipField source='tagCode' />
          </SingleFieldList>
        </ReferenceArrayField>
        <Box p={1} display='flex'>
          {campaigns.length > 0 && (
            <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
              {translate('resources.parkings.fields.campaigns')}
            </Typography>
          )}
        </Box>
        {campaigns.length > 0 && (
          <Box p={1} display='flex' color='grey.300' borderBottom={1} mb={1}>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.campaigns.fields.name')}
              </Typography>
            </Box>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.campaigns.fields.campaignCode')}
              </Typography>
            </Box>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.campaigns.fields.start')}
              </Typography>
            </Box>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.campaigns.fields.end')}
              </Typography>
            </Box>
          </Box>
        )}
        <Styles.ListContainer>
          {campaigns.map((campaign: any, key) => (
            <Box p={1} display='flex'>
              <Styles.SingleCampaignContainer key={key}>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {campaign.name}
                  </Typography>
                </Box>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {campaign.campaignCode}
                  </Typography>
                </Box>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {moment(campaign.start).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {moment(campaign.end).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Styles.SingleCampaignContainer>
            </Box>
          ))}
        </Styles.ListContainer>
      </SimpleShowLayout>
    </Show>
  )
}

export default PostShow
