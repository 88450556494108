import React from 'react'
import i18nProvider from '../../../assets/i18n'
import { Admin, Resource } from 'react-admin'
import PersonIcon from '@material-ui/icons/Person'
import ImageIcon from '@material-ui/icons/Image'
import LocalParkingIcon from '@material-ui/icons/LocalParking'
import LabelIcon from '@material-ui/icons/Label'
import LogIcon from '@material-ui/icons/VpnKey'
import { createBrowserHistory as createHistory } from 'history'
import authProvider from '../../../providers/authProvider'
import dataProvider from '../../../providers/dataProvider'
import {
  List as CampaignList,
  Create as CampaignCreate,
  Edit as CampaignEdit,
  Show as CampaignShow,
} from '../../pages/campaigns'
import {
  List as ParkingList,
  Create as ParkingCreate,
  Edit as ParkingEdit,
  Show as ParkingShow,
} from '../../pages/parkings'
import {
  List as ParkingTagList,
  Create as ParkingTagCreate,
  Edit as ParkingTagEdit,
  Show as ShowParkingTag,
} from '../../pages/parkingTags'
import { List as LogEntriesList, Show as ShowLogEntries } from '../../pages/logEntries'
import { Login } from '../../pages/auth'
import { List as UserList, Create as UserCreate, Edit as UserEdit, Show as UserShow } from '../../pages/users'
import theme from '../../../config/theme'
import customRoutes from '../../../routes'
import Layout from '../../layout'
import { roles } from '../../../assets/utils'
import find from 'lodash/find'
import split from 'lodash/split'

const history = createHistory()

export const App = (): JSX.Element => {
  return (
    <Admin
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      history={history}
      authProvider={authProvider}
      theme={theme}
      loginPage={Login}
      customRoutes={customRoutes}
      layout={Layout}
    >
      {(permissions: any) => {
        const permissionsList = split(permissions, ',')
        const isAdmin = find(permissionsList, o => o === roles.admin)
        return [
          isAdmin && (
            <Resource
              key='users'
              name='users'
              list={UserList}
              create={UserCreate}
              edit={UserEdit}
              show={UserShow}
              icon={PersonIcon}
            />
          ),
          <Resource
            key='campaigns'
            name='campaigns'
            list={CampaignList}
            create={isAdmin && CampaignCreate}
            edit={isAdmin && CampaignEdit}
            show={CampaignShow}
            icon={ImageIcon}
          />,
          <Resource
            key='parkings'
            name='parkings'
            list={ParkingList}
            create={isAdmin && ParkingCreate}
            edit={isAdmin && ParkingEdit}
            show={ParkingShow}
            icon={LocalParkingIcon}
          />,

          <Resource
            key='parkingTags'
            name='parkingTags'
            list={ParkingTagList}
            create={isAdmin && ParkingTagCreate}
            edit={isAdmin && ParkingTagEdit}
            show={ShowParkingTag}
            icon={LabelIcon}
          />,
          isAdmin && (
            <Resource key='logEntries' name='logEntries' list={LogEntriesList} show={ShowLogEntries} icon={LogIcon} />
          ),
          <Resource key='forgot' name='forgot' />,
          <Resource key='forgot' name='change-password' />,
        ]
      }}
    </Admin>
  )
}
