import * as React from 'react'
import { forwardRef } from 'react'
import { AppBar, UserMenu /*MenuItemLink, useTranslate*/ } from 'react-admin'
import Typography from '@material-ui/core/Typography'
//import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    width: '120px',
    filter: 'brightness(0) invert(1)',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
}))

// eslint-disable-next-line react/display-name
const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  return null
  //const translate = useTranslate()
  /*return (
    <MenuItemLink
      ref={ref}
      to='/configuration'
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  )*/
})

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
)

const CustomAppBar = (props: any) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />} className={classes.appBar}>
      <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
      <img className={classes.logo} src={'/empark-logo.png'} alt='Empark logo' />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
