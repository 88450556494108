import * as React from 'react'
import {
  List,
  downloadCSV,
  Datagrid,
  TextField,
  BooleanField,
  ArrayField,
  ChipField,
  SingleFieldList,
  EmailField,
  EditButton,
  DateField,
  FunctionField,
  useTranslate,
  ShowButton,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import Filters from './filters'
import { Empty } from '../../../organisms'

const PostList = (props: any): JSX.Element => {
  const translate = useTranslate()

  const exporter = (posts: any) => {
    const postsForExport = posts.map((post: any) => {
      return post
    })
    jsonExport(
      postsForExport,
      {
        headers: ['id', 'username', 'email', 'enabled', 'lastLogin', 'roles'],
        rename: [
          translate('resources.users.fields.id'),
          translate('resources.users.fields.username'),
          translate('resources.users.fields.email'),
          translate('resources.users.fields.enabled'),
          translate('resources.users.fields.lastLogin'),
          translate('resources.users.fields.roles'),
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'users')
      }
    )
  }

  return (
    <List
      {...props}
      filters={<Filters />}
      title={translate('resources.users.list.title')}
      empty={<Empty />}
      exporter={exporter}
    >
      <Datagrid>
        <TextField source='username' />
        <EmailField source='email' />
        <BooleanField source='enabled' />
        <DateField source='lastLogin' />
        <ArrayField source='roles'>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => {
                return <ChipField record={{ index: record, text: record }} source='text' />
              }}
            />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PostList
