import React from 'react'
import { useTranslate, useInput } from 'react-admin'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MomentUtils from '@date-io/moment'
import 'moment/locale/es'
import moment from 'moment'

const MyTimePicker = (props: any): JSX.Element => {
  const { input, className, meta, label, fullWidth, isRequired } = props

  const translate = useTranslate()

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl error={meta && meta.error && meta.touched} fullWidth={fullWidth} className={className}>
        <TimePicker
          label={`${translate(label)} ${isRequired ? '*' : ''}`}
          value={input.value ? moment(input.value, 'HH:mm') : null}
          minutesStep={15}
          onChange={value => {
            input.onChange(moment(value).format('HH:mm'))
          }}
        />
        {meta && meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

const TimeInput = (props: any): JSX.Element => {
  const { label, fullWidth } = props
  const { id, input, isRequired, meta } = useInput({ ...props })
  return <MyTimePicker fullWidth={fullWidth} label={label} id={id} input={input} isRequired={isRequired} meta={meta} />
}
export default TimeInput
