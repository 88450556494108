import { createMuiTheme } from '@material-ui/core/styles'

export const palette = {
  secondary: {
    light: '#A1A3A4',
    main: '#8D8E8F',
    dark: '#69696B',
    contrastText: '#fff',
  },
  primary: {
    light: '#fbc01c',
    main: '#f4a021',
    dark: '#f08c24',
    contrastText: '#fff',
  },
}

const myTheme = createMuiTheme({
  palette,
  typography: {
    fontSize: 15,
  },
  overrides: {
    MuiToolbar: {
      root: {
        '& > form': {
          marginTop: '0px',
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
      },
    },
    MuiFormControl: {
      fullWidth: {
        width: '100% !important',
      },
    },
  },
})

export default myTheme
