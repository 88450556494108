import * as React from 'react'
import { Box } from '@material-ui/core'
import { SimpleForm, TextInput, BooleanInput } from 'react-admin'

export const PostCreate = (props: any): JSX.Element => {
  return (
    <SimpleForm {...props} redirect='list'>
      <Box p={1}>
        <TextInput source='tagCode' label='resources.parkingTags.fields.tagCode' fullWidth />
      </Box>
      <Box p={1}>
        <TextInput source='description' label='resources.parkingTags.fields.description' fullWidth />
      </Box>
      <Box p={1}>
        <BooleanInput source='mandatory' label='resources.parkingTags.fields.mandatory' />
      </Box>
    </SimpleForm>
  )
}

export default PostCreate
