import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  timeRangesForm: {
    maxWidth: '662px',
  },
  root: {
    width: '100%',
  },
  video: {
    maxHeight: '300px',
  },
})

export default useStyles
