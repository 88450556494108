import * as React from 'react'
import {
  Filter,
  TextInput,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteArrayInput,
  SelectInput,
  useTranslate,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  selectArrayInput: {
    minWidth: '200px',
  },
})

const Filters: React.FC = (props: any): JSX.Element => {
  const translate = useTranslate()
  const classes = useStyles()
  const DAYS_CHOICES = [
    { id: 1, name: translate(`weeks.${1}`) },
    { id: 2, name: translate(`weeks.${2}`) },
    { id: 3, name: translate(`weeks.${3}`) },
    { id: 4, name: translate(`weeks.${4}`) },
    { id: 5, name: translate(`weeks.${5}`) },
    { id: 6, name: translate(`weeks.${6}`) },
    { id: 0, name: translate(`weeks.${0}`) },
  ]

  const AVAILABILITY_CHOICES = [
    { id: 'ACTIVE', name: translate(`availability.${1}`) },
    { id: 'INACTIVE', name: translate(`availability.${2}`) },
  ]

  return (
    <Filter {...props}>
      <TextInput source='name' alwaysOn />
      <TextInput source='campaignCode' alwaysOn />
      <TextInput source='start' type='time' alwaysOn inputProps={{ step: 900 }} />
      <TextInput source='end' type='time' alwaysOn inputProps={{ step: 900 }} />
      <SelectArrayInput source='weekDay' className={classes.selectArrayInput} choices={DAYS_CHOICES} alwaysOn />
      <ReferenceInput
        source='tags'
        reference='parkingTags'
        filterToQuery={(searchText: string) => ({ tagCode: searchText })}
        alwaysOn
      >
        <AutocompleteArrayInput optionText='tagCode' />
      </ReferenceInput>
      <ReferenceInput source='parking' reference='parkings' alwaysOn>
        <SelectInput optionText='description' />
      </ReferenceInput>
      <SelectInput source='status' choices={AVAILABILITY_CHOICES} alwaysOn />
    </Filter>
  )
}

export default Filters
