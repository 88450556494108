import * as React from 'react'
import {
  List,
  downloadCSV,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  useTranslate,
  ShowButton,
  useLocale,
  Pagination,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import Filters from './filters'
import { Empty } from '../../../organisms'

const PostList = (props: any): JSX.Element => {
  const translate = useTranslate()
  const locale = useLocale()
  const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

  const exporter = (posts: any) => {
    const postsForExport = posts.map((post: any) => {
      return post
    })
    jsonExport(
      postsForExport,
      {
        headers: ['id', 'action', 'loggedAt', 'objectId', 'objectClass', 'username', 'description'],
        rename: [
          translate('resources.logEntries.fields.id'),
          translate('resources.logEntries.fields.action'),
          translate('resources.logEntries.fields.loggedAt'),
          translate('resources.logEntries.fields.objectId'),
          translate('resources.logEntries.fields.objectClass'),
          translate('resources.logEntries.fields.username'),
          translate('resources.logEntries.fields.description'),
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'logs')
      }
    )
  }

  return (
    <List
      {...props}
      pagination={<PostPagination />}
      filters={<Filters />}
      title={translate('resources.logEntries.list.title')}
      empty={<Empty />}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='username' />
        <TextField source='action' />
        <DateField source='loggedAt' locales={locale} showTime />
        <TextField source='objectId' />
        <ReferenceField source='objectId' reference='campaigns' label={translate('resources.campaigns.nameSingular')}>
          <TextField source='campaignCode' />
        </ReferenceField>
        <ReferenceField source='objectId' reference='parkings' label={translate('resources.parkings.nameSingular')}>
          <TextField source='customId' />
        </ReferenceField>
        <TextField source='objectClass' />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PostList
