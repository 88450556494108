import * as React from 'react'
import { Route } from 'react-router-dom'
import { ForbidenPass, NewPass } from '../components/pages/auth'

const routes = [
  <Route key='/forgot' exact path='/forgot' noLayout component={ForbidenPass} />,
  <Route key='/resetPass' exact path='/resetPass' noLayout component={NewPass} />,
]

export default routes
