import * as React from 'react'
import { Edit } from 'react-admin'
import Form from '../form'

export const PostEdit = (props: any): JSX.Element => (
  <Edit {...props}>
    <Form />
  </Edit>
)

export default PostEdit
