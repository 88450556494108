import * as React from 'react'
import { Create } from 'react-admin'
import Form from '../form'

export const PostCreate = (props: any): JSX.Element => {
  return (
    <Create {...props}>
      <Form />
    </Create>
  )
}

export default PostCreate
