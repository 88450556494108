import * as React from 'react'
import { List, downloadCSV, Datagrid, TextField, EditButton, BooleanField, ShowButton, useTranslate } from 'react-admin'
import jsonExport from 'jsonexport/dist'
import Filters from './filters'
import { Empty } from '../../../organisms'
import { roles } from '../../../../assets/utils'
import _find from 'lodash/find'
import _split from 'lodash/split'

const PostList = (props: any): JSX.Element => {
  const translate = useTranslate()
  const { permissions } = props
  const permissionsList = _split(permissions, ',')
  const isAdmin = _find(permissionsList, o => o === roles.admin)

  const exporter = (posts: any) => {
    const postsForExport = posts.map((post: any) => {
      return post
    })
    jsonExport(
      postsForExport,
      {
        headers: ['id', 'tagCode', 'description', 'mandatory'],
        rename: [
          translate('resources.parkingTags.fields.id'),
          translate('resources.parkingTags.fields.tagCode'),
          translate('resources.parkingTags.fields.description'),
          translate('resources.parkingTags.fields.mandatory'),
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'tags')
      }
    )
  }

  return (
    <List {...props} filters={<Filters />} empty={<Empty />} exporter={exporter}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='tagCode' />
        <TextField source='description' />
        <BooleanField source='mandatory' />
        {isAdmin && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PostList
