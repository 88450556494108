import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './styles'
import { SimpleForm, TextInput, AutocompleteArrayInput, ReferenceArrayInput, required } from 'react-admin'

export const PostCreate = (props: any): JSX.Element => {
  const classes = useStyles()
  return (
    <SimpleForm {...props} redirect='list'>
      <Box display='flex' className={classes.root}>
        <Box p={1} flex={1}>
          <TextInput source='customId' label='resources.parkings.fields.customId' fullWidth validate={[required()]} />
        </Box>
        <Box p={1} flex={1}>
          <TextInput
            source='parkingCode'
            label='resources.parkings.fields.parkingCode'
            fullWidth
            validate={[required()]}
          />
        </Box>
        <Box p={1} flex={1}>
          <TextInput
            source='description'
            label='resources.parkings.fields.description'
            fullWidth
            validate={[required()]}
          />
        </Box>
      </Box>
      <Box display='flex' className={classes.root}>
        <Box p={1} flex={1}>
          <ReferenceArrayInput
            source='tags'
            reference='parkingTags'
            label='resources.parkingTags.name'
            filterToQuery={(searchText: string) => ({ tagCode: searchText })}
            sort={{ field: 'tagCode', order: 'ASC' }}
            fullWidth
          >
            <AutocompleteArrayInput optionText='tagCode' />
          </ReferenceArrayInput>
        </Box>
      </Box>
    </SimpleForm>
  )
}

export default PostCreate
