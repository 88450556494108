import React, { useEffect, useState } from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ArrayField,
  Datagrid,
  useTranslate,
} from 'react-admin'
import * as Styles from './styles'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ColoredChipField } from '../../../atoms'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { getAssocParkings } from '../../../../api'

const useStyles = makeStyles({
  singleList: {
    margin: '0px',
    pointerEvents: 'none',
  },
  file: {
    maxWidth: '300px',
    maxHeight: '300px',
  },
  container: {
    padding: '10px',
    marginBottom: '10px',
  },
})

const WeekDays = (props: any) => {
  const { record } = props
  const translate = useTranslate()
  return <ChipField record={{ index: record, text: translate(`weeks.${record}`) }} source='text' />
}

const LayOut = ({ record }: any) => {
  const classes = useStyles()
  const translate = useTranslate()

  const textField = (field: string) => (
    <>
      <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
        {translate(`resources.campaigns.fields.${field}`)}
      </Typography>
      <Typography>{record?.[field]}</Typography>
    </>
  )

  const dateField = (field: string) => (
    <>
      <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
        {translate(`resources.campaigns.fields.${field}`)}
      </Typography>
      <Typography>{moment(record?.[field]).format('DD/MM/YYYY')}</Typography>
    </>
  )

  return (
    <>
      {(record?.url || record?.secondUrl || record?.thirdUrl) && (
        <Box pb={3} display='flex'>
          {record?.url && (
            <Box p={1}>
              {record?.type === 1 ? (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.firstImage')}
                  </Typography>
                  <img className={classes.file} src={record.url} alt='Campaign' />
                </>
              ) : (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.firstVideo')}
                  </Typography>
                  <video className={classes.file} src={record.url}></video>
                </>
              )}
            </Box>
          )}
          {record?.secondUrl && (
            <Box p={1}>
              {record?.type === 1 ? (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.secondImage')}
                  </Typography>
                  <img className={classes.file} src={record.secondUrl} alt='Campaign' />
                </>
              ) : (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.secondVideo')}
                  </Typography>
                  <video className={classes.file} src={record.secondUrl}></video>
                </>
              )}
            </Box>
          )}
          {record?.thirdUrl && (
            <Box p={1}>
              {record?.type === 1 ? (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.thirdImage')}
                  </Typography>
                  <img className={classes.file} src={record.thirdUrl} alt='Campaign' />
                </>
              ) : (
                <>
                  <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
                    {translate('resources.campaigns.fields.thirdVideo')}
                  </Typography>
                  <video className={classes.file} src={record.thirdUrl}></video>
                </>
              )}
            </Box>
          )}
        </Box>
      )}
      <Box p={1} display='flex'>
        <Box width='25%'>{textField('name')}</Box>
        <Box width='25%'>{textField('campaignCode')}</Box>
        <Box width='25%'>
          <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
            {translate(`resources.campaigns.fields.active`)}
          </Typography>
          {record?.active ? <Typography>Si</Typography> : <Typography>No</Typography>}
        </Box>
        <Box width='25%'>{textField('duration')}</Box>
      </Box>
      <Box p={1} pb={3} display='flex'>
        <Box width='25%'>{dateField('start')}</Box>
        <Box width='25%'>{dateField('end')}</Box>
        <Box width='50%'>{textField('warningMail')}</Box>
      </Box>
    </>
  )
}

export const PostShow = (props: any): JSX.Element => {
  const classes = useStyles()
  const [parkings, setParkings] = useState([])
  const translate = useTranslate()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function fetchParkings(id: number) {
      try {
        const response = await getAssocParkings(id)
        setParkings(response.data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchParkings(props?.id)
  }, [])
  /* eslint-disable */

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <LayOut />
        <ArrayField className={classes.container} source='timeRanges' label='resources.campaigns.fields.timeRanges'>
          <Datagrid>
            <TextField source='start' />
            <TextField source='end' />
            <ArrayField source='weekDay'>
              <SingleFieldList className={classes.singleList}>
                <WeekDays />
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ArrayField>
        <ReferenceArrayField className={classes.container} reference='parkingTags' source='tags'>
          <SingleFieldList>
            <ColoredChipField source='tagCode' />
          </SingleFieldList>
        </ReferenceArrayField>
        <Box p={1} display='flex'>
          {parkings.length > 0 && (
            <Typography variant='caption' component='div' color='textSecondary' gutterBottom>
              {translate('resources.campaigns.fields.parkings')}
            </Typography>
          )}
        </Box>
        {parkings.length > 0 && (
          <Box p={1} display='flex' color='grey.300' borderBottom={1} mb={1}>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.parkings.fields.customId')}
              </Typography>
            </Box>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.parkings.fields.description')}
              </Typography>
            </Box>
            <Box width='25%' pl={1}>
              <Typography variant='body2' component='div' color='textSecondary' gutterBottom>
                {translate('resources.parkings.fields.parkingCode')}
              </Typography>
            </Box>
          </Box>
        )}
        <Styles.ListContainer>
          {parkings.map((parking: any, key) => (
            <Box p={1} display='flex'>
              <Styles.SingleParkingContainer key={key}>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {parking.customId}
                  </Typography>
                </Box>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {parking.description}
                  </Typography>
                </Box>
                <Box width='25%' pl={1}>
                  <Typography variant='body2' component='div'>
                    {parking.parkingCode}
                  </Typography>
                </Box>
              </Styles.SingleParkingContainer>
            </Box>
          ))}
        </Styles.ListContainer>
      </SimpleShowLayout>
    </Show>
  )
}

export default PostShow
