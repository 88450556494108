import { getSignedUrl } from '../../api'
import get from 'lodash/get'
import map from 'lodash/map'
import last from 'lodash/last'

export const getIds = (json, key) => map(get(json, key, []), ({ id }) => id)

const getBlob = async fileUri => {
  const resp = await fetch(fileUri)
  const imageBody = await resp.blob()
  return imageBody
}

const uploadImage = async (uploadUrl, res) => {
  const headerExt = res.type

  return fetch(uploadUrl, {
    headers: { 'Content-Type': headerExt, 'x-amz-acl': 'public-read' },
    method: 'PUT',
    body: res,
  })
}

export const getUploadUrl = async (base64, type) => {
  const res = await getBlob(base64)
  const extension = last(res.type.split('/'))
  const response = await getSignedUrl(extension)
  const uploadUrl = get(response, 'data.uploadUrl')
  const getUrl = get(response, 'data.getUrl')
  await uploadImage(uploadUrl, res)
  return {getUrl, extension} 
}
