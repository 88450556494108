export default {
  views: {
    users: 'Usuarios',
    campaigns: 'Campañas',
    parkings: 'Parkings',
    parkingTags: 'Tags',
  },
  resources: {
    users: {
      name: 'Usuarios',
      create: {
        title: 'Crear usuario',
      },
      list: {
        title: 'Listado de usuarios',
      },
      fields: {
        id: 'Id',
        username: 'Nombre',
        email: 'Email',
        enabled: 'Habilitado',
        lastLogin: 'Última conexión',
        roles: 'Roles',
        password: 'Contraseña',
        repeatPassword: 'Repetir contraseña',
      },
    },
    parkings: {
      name: 'Parkings',
      nameSingular: 'Parking',
      create: {
        title: 'Crear parking',
      },
      list: {
        title: 'Listado de parkings',
      },
      fields: {
        id: 'Id',
        type: 'Tipo',
        customId: 'Id personalizado',
        parkingCode: 'Código de parking',
        description: 'Descripción',
        tags: 'Tags',
        tag: {
          id: 'Id',
          code: 'Código',
          description: 'Descripción',
        },
        campaigns: 'Campañas asociadas',
      },
    },
    parkingTags: {
      name: 'Tags',
      fields: {
        type: 'Tipo',
        id: 'Id',
        tagCode: 'Código',
        description: 'Descripción',
        mandatory: 'Obligatorio',
      },
    },
    campaigns: {
      name: 'Campañas',
      nameSingular: 'Campaña',
      cropTitle: 'Proporción de crop',
      cropWidth: 'Ancho en px',
      cropHeight: 'Alto en px',
      validImgFormat: 'Formatos soportados: jpg, jpeg, png, gif',
      validVideoFormat: 'Formatos soportados: mp4, mov, webm',
      create: {
        title: 'Crear campaña',
      },
      list: {
        title: 'Listado de campañas',
      },
      fields: {
        id: 'Id',
        hour: 'Hora',
        type: 'Tipo',
        email: 'Email de avisos',
        startDate: 'Comienzo de campaña',
        endDate: 'Fin de campaña',
        image: 'Imagen',
        video: 'Vídeo',
        firstImage: 'Saga 32" (1080x740)',
        secondImage: 'Saga 18,5" (315x768)',
        thirdImage: 'Skidata (1080x1110)',
        firstVideo: 'Saga 32"',
        secondVideo: 'Saga 18,5"',
        thirdVideo: 'Skidata',
        title: 'Título',
        timeRanges: 'Rangos horarios',
        active: 'Activo',
        start: 'Inicio',
        end: 'Fin',
        weekDay: 'Día de la semana',
        checkAll: 'Marcar todos los días',
        duration: 'Duración(s)',
        tags: 'Tags',
        name: 'Nombre',
        warningMail: 'Email de avisos',
        parkings: 'Parkings asociados',
        campaignCode: 'Cod. Campaña',
        status: 'Estado',
      },
      errors: {
        endIsBeforeStart: 'La hora de fin no puede ser menor a la de inicio',
        quarterError: 'Solo se aceptan intervalos de 15 minutos',
        endIsBeforeStartDate: 'La fecha de fin de camapaña no puede ser anterior a la de inicio',
      },
    },
    logEntries: {
      name: 'Logs',
      list: {
        title: 'Listado de logs',
      },
      fields: {
        id: 'Id',
        username: 'Usuario',
        action: 'Acción',
        loggedAt: 'Fecha y hora',
        objectId: 'Id de objeto',
        objectClass: 'Entidad',
        description: 'Descripción',
      },
    },
  },

  weeks: {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
    0: 'Domingo',
  },

  availability: {
    1: 'Activa',
    2: 'Finalizada',
  },

  login: {
    forgotPass: '¿No recuerda la contraseña?',
    email: 'Email',
    passChangeSuccess: 'La contraseña se ha modificado con éxito',
    code: 'Código',
    password: 'Contraseña',
    newpassword: 'Nueva contraseña',
    repeatpassword: 'Repite contraseña',
    gotologin: 'Ir al Login',
    headMail: 'Si el correo está registrado se le enviará un email con el código.',
    seeMail: 'Revisa tu correo',
    errors: {
      samePass: 'Las contraseñas no coinciden',
      codeError: 'El código no es correcto',
    },
  },

  errors: {
    oneUppercase: 'Debe tener por lo menos una letra mayúscula',
    oneSpecialChar: 'Tiene que tener por lo menos un caracter especial',
    atLeastOneNumber: 'Tiene que tener por lo menos un número',
    atLeast8chars: 'Tiene que tener por lo menos 8 caracteres',
    isEqualTo: 'El campo no coincide',
  },

  create: 'Crear',
  update: 'Actualizar',
  delete: 'Borrar',
}
