import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 20px;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SingleCampaignContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 16px;
`
