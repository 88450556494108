import * as React from 'react'
import {
  List,
  downloadCSV,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ArrayField,
  ChipField,
  SingleFieldList,
  FunctionField,
  useTranslate,
  EditButton,
  ShowButton,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { makeStyles } from '@material-ui/core/styles'
import * as Styles from './styles'
import Filters from './filters'
import { ColoredChipField } from '../../../atoms'
import { Empty } from '../../../organisms'
import { roles } from '../../../../assets/utils'
import _find from 'lodash/find'
import _split from 'lodash/split'

const useStyles = makeStyles({
  singleList: {
    margin: '0px',
    pointerEvents: 'none',
  },
})

const WeekDays = (props: any) => {
  const { record } = props
  const translate = useTranslate()
  return <ChipField record={{ index: record, text: translate(`weeks.${record}`) }} source='text' />
}

const PostList = (props: any): JSX.Element => {
  const { permissions } = props
  const permissionsList = _split(permissions, ',')
  const isAdmin = _find(permissionsList, o => o === roles.admin)
  const translate = useTranslate()
  const classes = useStyles()

  const exporter = (posts: any) => {
    const postsForExport = posts.map((post: any) => {
      const { url, secondUrl, thirdUrl, extension, secondExtension, thirdExtension, type, ...postForExport } = post
      return postForExport
    })
    jsonExport(
      postsForExport,
      {
        headers: [
          'id',
          'status',
          'name',
          'campaignCode',
          'start',
          'end',
          'warningMail',
          'duration',
          'active',
          'timeRanges.id',
          'timeRanges.start',
          'timeRanges.end',
          'timeRanges.weekDay',
          'tags.id',
          'tags.tagCode',
          'tags.description',
          'tags.mandatory',
        ],
        rename: [
          translate('resources.campaigns.fields.id'),
          translate('resources.campaigns.fields.status'),
          translate('resources.campaigns.fields.name'),
          translate('resources.campaigns.fields.campaignCode'),
          translate('resources.campaigns.fields.startDate'),
          translate('resources.campaigns.fields.endDate'),
          translate('resources.campaigns.fields.warningMail'),
          translate('resources.campaigns.fields.duration'),
          translate('resources.campaigns.fields.active'),
          translate('resources.campaigns.fields.timeRanges'),
          translate('resources.campaigns.fields.start'),
          translate('resources.campaigns.fields.end'),
          translate('resources.campaigns.fields.weekDay'),
          translate('resources.parkingTags.fields.id'),
          translate('resources.parkingTags.fields.tagCode'),
          translate('resources.parkingTags.fields.description'),
          translate('resources.parkingTags.fields.mandatory'),
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'campaigns')
      }
    )
  }

  const CreativityField = ({ record }: any) => {
    const type = record?.type
    if (type === 1) {
      return <Styles.Image src={record?.url || record?.secondUrl} />
    } else {
      return <video src={record?.url || record?.secondUrl} width='90px' height='90px'></video>
    }
  }

  return (
    <List
      {...props}
      filters={<Filters />}
      title={translate('resources.campaigns.list.title')}
      empty={<Empty />}
      exporter={exporter}
    >
      <Datagrid>
        <CreativityField />
        <TextField source='name' />
        <TextField source='campaignCode' />
        <DateField source='start' />
        <DateField source='end' />
        <ArrayField source='timeRanges'>
          <Datagrid>
            <TextField source='start' />
            <TextField source='end' />
            <ArrayField source='weekDay'>
              <SingleFieldList className={classes.singleList}>
                <WeekDays />
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ArrayField>
        <ArrayField source='tags'>
          <SingleFieldList className={classes.singleList}>
            <ColoredChipField source='tagCode' clickable={false} />
          </SingleFieldList>
        </ArrayField>
        <FunctionField render={(record: any) => `${record?.duration}''`} />
        <BooleanField source='active' />
        {isAdmin && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default PostList
