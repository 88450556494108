import * as api from '../config/api'
import axios from 'axios'

export const getCampaigns = async (id: number) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/parkings/customId/${id}/campaigns?historical=true`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getParkings = async (id: number) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/parkings/${id}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getAssocParkings = async (id: number) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/campaigns/${id}/parkings`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getSignedUrl = (extension: any) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/campaigns/upload/signedUrl?extension=${extension}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
