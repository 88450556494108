import * as React from 'react'
import { Filter, TextInput, SelectInput, ReferenceInput, useTranslate } from 'react-admin'

const Filters: React.FC = (props: any): JSX.Element => {
  const translate = useTranslate()

  return (
    <Filter {...props}>
      <TextInput source='username' alwaysOn />
      <SelectInput
        source='action'
        choices={[
          { id: 'create', name: translate('create') },
          { id: 'update', name: translate('update') },
          { id: 'delete', name: translate('delete') },
        ]}
        alwaysOn
      />
      <ReferenceInput
        source='parkingObjectId'
        reference='parkings'
        label={translate('resources.parkings.name')}
        alwaysOn
      >
        <SelectInput optionText='customId' />
      </ReferenceInput>
      <ReferenceInput
        source='campaignObjectId'
        reference='campaigns'
        label={translate('resources.campaigns.name')}
        alwaysOn
      >
        <SelectInput optionText='campaignCode' />
      </ReferenceInput>
    </Filter>
  )
}

export default Filters
