import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, useLocale } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  pre: {
    margin: '0',
    padding: '8px 0 4px',
  },
})

const LayOut = ({ record }: any) => {
  const classes = useStyles()
  const formattedDescription = (field: string) => (
    <div className={classes.container}>
      <Typography variant='caption' component='div' color='textSecondary'>
        Descripción
      </Typography>
      <pre className={classes.pre}>
        <Typography variant='body2' component='div'>
          {record?.[field]}
        </Typography>
      </pre>
    </div>
  )

  return formattedDescription('description')
}

export const PostShow = (props: any): JSX.Element => {
  const locale = useLocale()

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='username' />
        <TextField source='action' />
        <DateField source='loggedAt' locales={locale} showTime />
        <TextField source='objectId' />
        <TextField source='objectClass' />
        <LayOut />
      </SimpleShowLayout>
    </Show>
  )
}

export default PostShow
