import * as React from 'react'
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  email,
  SelectArrayInput,
  PasswordInput,
  useTranslate,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { isEqualTo, oneUppercase, oneSpecialChar, atLeastOneNumber, atLeast8chars } from '../../../utils/validations'

const PasswordInputBlock = (props: any): JSX.Element => {
  const { record } = props
  const translate = useTranslate()
  const isPassword = record.id
    ? [
        oneUppercase(translate('errors.oneUppercase')),
        oneSpecialChar(translate('errors.oneSpecialChar')),
        atLeastOneNumber(translate('errors.atLeastOneNumber')),
        atLeast8chars(translate('errors.atLeast8chars')),
      ]
    : [
        required(),
        oneUppercase(translate('errors.oneUppercase')),
        oneSpecialChar(translate('errors.oneSpecialChar')),
        atLeastOneNumber(translate('errors.atLeastOneNumber')),
        atLeast8chars(translate('errors.atLeast8chars')),
      ]
  return (
    <Box display='inline-flex'>
      <Box flex={1} p={1}>
        <PasswordInput
          {...props}
          source='plain_password'
          label={translate('resources.users.fields.password')}
          validate={isPassword}
        />
      </Box>
      <Box flex={1} p={1}>
        <PasswordInput
          {...props}
          source='repeat_plain_password'
          label={translate('resources.users.fields.repeatPassword')}
          validate={
            record.id
              ? [isEqualTo('plain_password', translate('errors.isEqualTo'))]
              : [required(), isEqualTo('plain_password', translate('errors.isEqualTo'))]
          }
        />
      </Box>
    </Box>
  )
}

export const PostCreate = (props: any): JSX.Element => {
  return (
    <SimpleForm {...props} redirect='list'>
      <TextInput source='email' type='email' validate={[required(), email()]} />
      <BooleanInput source='enabled' />
      <SelectArrayInput
        {...props}
        source={'roles'}
        choices={[
          { id: 'ROLE_ADMIN', name: 'Admin' },
          { id: 'ROLE_READ_ADMIN', name: 'Usuario solo lectura' },
        ]}
      />
      <PasswordInputBlock />
    </SimpleForm>
  )
}

export default PostCreate
