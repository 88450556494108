import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Avatar from '@material-ui/core/Avatar'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import { defaultTheme, Notification } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../../config/theme'

const Login = (props: any) => {
  const { className, children, ...rest } = props
  const myTheme = createMuiTheme(theme)
  const containerRef = React.createRef<HTMLDivElement>()

  const useStyles = makeStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
      textAlign: 'center',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: myTheme.palette.secondary.main,
    },
    logo: {
      width: '244px',
      marginBottom: '57px',
    },
  })

  const classes = useStyles()
  return (
    <MuiThemeProvider theme={myTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <div className={classes.card}>
          <img className={classes.logo} src={'/empark-logo.png'} alt='empark logo' />
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          {children}
        </div>
        <Notification />
      </div>
    </MuiThemeProvider>
  )
}

Login.propTypes = {
  backgroundImage: PropTypes.string,
  loginForm: PropTypes.element,
  theme: PropTypes.object,
}

Login.defaultProps = {
  theme: defaultTheme,
  loginForm: undefined,
}

export default Login
