import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  EmailField,
  DateField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ChipField,
} from 'react-admin'

export const PostShow = (props: any): JSX.Element => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='username' />
        <EmailField source='email' />
        <BooleanField source='enabled' />
        <DateField source='lastLogin' />
        <ArrayField source='roles'>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => {
                return <ChipField record={{ index: record, text: record }} source='text' />
              }}
            />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

export default PostShow
