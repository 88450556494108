import styled from 'styled-components'
import { palette } from '../../../config/theme'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 10px;
`

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
`

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2;
  border: 1px solid ${palette.primary.main};
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
`

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`

export const Dropzone = styled.div`
  display: inline-block;
  padding: 5px;
  border: dashed 2px ${palette.primary.main};
  line-height: 0px;
  cursor: pointer;
  width: 300px;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const Img = styled.img`
  display: block;
  width: auto;
  height: 100%;
  line-height: 0px;
`

export const Video = styled.video`
  width: 300px;
  max-height: 300px;
`

export const Preview = styled.img`
  display: block;
  width: auto;
  line-height: 0px;
`
