import moment from 'moment'
import get from 'lodash/get'

export const endValidation = (startTime: string, message: string) => (value: string, allValues: any): any => {
  const timeStart = moment(get(allValues, startTime), 'hh:mm')
  const timeEnd = moment(value, 'hh:mm')
  const error = timeEnd.isBefore(timeStart) ? message : undefined
  return error
}

export const quarterMinValidation = (message: string) => (value: string): any => {
  const minutes = parseInt(moment(value, 'hh:mm').format('mm'))
  return minutes % 15 !== 0 ? message : undefined
}

export const dateValidation = (startDate: string, message: string) => (value: string, allValues: any): any => {
  const dateStart = moment(get(allValues, startDate), 'YYYY-MM-DD')
  const dateEnd = moment(value, 'YYYY-MM-DD')
  const error = dateEnd.isBefore(dateStart) ? message : undefined
  return error
}
