import * as React from 'react'
import { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslate, useNotify, useSafeSetState } from 'ra-core'
import useStyles from '../styles'
import { useHistory } from 'react-router-dom'

interface Props {
  redirectTo?: string
}

interface FormData {
  email: string | undefined
}

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}: any) => {
  return <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
}

const LoginForm: FunctionComponent<Props> = (props: any) => {
  const [loading, setLoading] = useSafeSetState(false)
  const history = useHistory()
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles(props)

  const validate = (values: FormData) => {
    const errors: FormData = { email: undefined }

    if (!values.email) {
      errors.email = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values: any) => {
    setLoading(true)
    const request = new Request(process.env.REACT_APP_API_PATH + '/forgot', {
      method: 'POST',
      body: JSON.stringify({ ...values }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(() => {
        setLoading(false)
        history.push('/resetPass')
      })
      .catch(error => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        )
      })
  }

  return (
    <>
      <DialogContent>
        <DialogContentText>{translate('login.headMail')}</DialogContentText>
      </DialogContent>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  id='email'
                  name='email'
                  component={Input}
                  label={translate('login.email')}
                  type='email'
                  disabled={loading}
                  autoComplete='email'
                />
              </div>
            </div>
            <CardActions>
              <Button variant='contained' type='submit' color='primary' disabled={loading} className={classes.button}>
                {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                {translate('ra.auth.sign_in')}
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant='contained'
                type='button'
                onClick={() => history.push('/login')}
                color='secondary'
                disabled={loading}
                className={classes.button}
              >
                {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                {translate('login.gotologin')}
              </Button>
            </CardActions>
          </form>
        )}
      />
    </>
  )
}

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

export default LoginForm
