import * as React from 'react'
import { Filter, TextInput, ReferenceInput, AutocompleteArrayInput } from 'react-admin'

const Filters: React.FC = (props: any): JSX.Element => {
  return (
    <Filter {...props}>
      <TextInput source='customId' alwaysOn />
      <TextInput source='parkingCode' alwaysOn />
      <TextInput source='description' alwaysOn />
      <ReferenceInput
        source='tags'
        reference='parkingTags'
        filterToQuery={(searchText: string) => ({ tagCode: searchText })}
        alwaysOn
      >
        <AutocompleteArrayInput optionText='tagCode' />
      </ReferenceInput>
    </Filter>
  )
}

export default Filters
