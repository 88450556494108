import get from 'lodash/get'

export const oneUppercase = (message?: string) => (value: string): any => {
  if (value && !value.match(/^(?=.*[A-Z])/)) {
    return message || 'Debe tener por lo menos una letra mayúscula'
  }
  return null
}

export const oneSpecialChar = (message?: string) => (value: string): any => {
  if (value && !value.match(/^(?=.*[!@#$&%()*])/)) {
    return message || 'Tiene que tener por lo menos un caracter especial'
  }
  return null
}

export const atLeastOneNumber = (message?: string) => (value: string): any => {
  if (value && !value.match(/^(?=.*[0-9])/)) {
    return message || 'Tiene que tener por lo menos un número'
  }
  return null
}

export const atLeast8chars = (message?: string) => (value: string): any => {
  if (value && !value.match(/^.{8}/)) {
    return message || 'Tiene que tener por lo menos 8 caracteres'
  }
  return null
}

export const isEqualTo = (equalValue: string, message?: string) => (value: string, allValues: any): any => {
  if (value && value !== get(allValues, equalValue)) {
    return message || 'El campo no coincide'
  }
  return null
}
