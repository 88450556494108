import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { ChipField } from 'react-admin'
import { palette } from '../../../config/theme'

const useStyles = makeStyles({
  mandatory: { backgroundColor: palette.primary.main },
})

const ColoredChipField = (props: any) => {
  const classes = useStyles()

  return (
    <ChipField
      className={classnames({
        [classes.mandatory]: props?.record?.mandatory === true,
      })}
      {...props}
    />
  )
}

export default ColoredChipField
