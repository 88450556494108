import { stringify } from 'query-string'
import { fetchUtils } from 'ra-core'
import { getIds, getUploadUrl } from './utils.js'
import omit from 'lodash/omit'

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const formatFileData = async (formData) => {
      let data= Object.assign(formData)
      if (data?.file) {
        const fileData = await getUploadUrl(data?.file, data?.type)
        data.url = fileData.getUrl
        data.extension = fileData.extension
        data = omit(data, 'file')
      }
      if (data?.secondFile) {
        const fileData = await getUploadUrl(data?.secondFile, data?.type)
        data.secondUrl = fileData.getUrl
        data.secondExtension = fileData.extension
        data = omit(data, 'secondFile')
      }
      if (data?.thirdFile) {
        const fileData = await getUploadUrl(data?.thirdFile, data?.type)
        data.thirdUrl = fileData.getUrl
        data.thirdExtension = fileData.extension

        data = omit(data, 'thirdFile')
      }
      if (data?.type === 1 && data?.fileCropped) {
        const fileData = await getUploadUrl(data?.fileCropped, data?.type)
        data.url = fileData.getUrl
        data.extension = fileData.extension
        data = omit(data, 'fileCropped')
      } else if (data?.type === 1 && data?.secondFileCropped) {
        const fileData = await getUploadUrl(data?.secondFileCropped, data?.type)
        data.secondUrl = fileData.getUrl
        data.secondExtension = fileData.extension
        data = omit(data, 'secondFileCropped')
      } else if (data?.type === 1 && data?.thirdFileCropped) {
        const fileData = await getUploadUrl(data?.thirdFileCropped, data?.type)
        data.thirdUrl = fileData.getUrl
        data.thirdExtension = fileData.extension
        data = omit(data, 'thirdFileCropped')
      }

      return data
}

export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    if (resource === 'logEntries') {
      const identity = []
      if (params?.filter?.parkingObjectId) {
        identity.push({ objectId: params.filter.parkingObjectId, objectClass: 'App\\Entity\\Parking' })
        params.filter = omit(params.filter, 'parkingObjectId')
      }
      if (params?.filter?.campaignObjectId) {
        identity.push({ objectId: params.filter.campaignObjectId, objectClass: 'App\\Entity\\Campaign' })
        params.filter = omit(params.filter, 'campaignObjectId')
      }
      params.filter.identity = identity
    }
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([page, perPage]),
      filter: JSON.stringify(params.filter),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
        )
      }
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      }
    })
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      //CUSTOM
      if (resource === 'parkings' || resource === 'campaigns') {
        const ids = getIds(json, 'tags')
        json.tags = ids
      }
      return {
        data: json,
      }
    })
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([page, perPage]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
        )
      }
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      }
    })
  },

  update: async (resource, params) => {
    //CUSTOM
    let data = params.data
    if (resource === 'campaigns') {
      data = await formatFileData(data)
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }))
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: async (resource, params) => {
    //CUSTOM
    let data = params.data
    if (resource === 'campaigns') {
      data = await formatFileData(data)
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(({ json }) => ({
      data: { ...data, id: json.id },
    }))
  },

  patch: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
})
