import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 20px;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SingleParkingContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
