import React from 'react'
import { Field } from 'react-final-form'
import { useDropzone } from 'react-dropzone'
import * as Styles from './styles'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTranslate } from 'react-admin'

interface ImageInput {
  source?: string
  id?: string
  onChange?: () => []
}

interface Thumb {
  preview: string
}

interface Thumbs {
  files: string[] | string
}

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const Previews = (props: any): JSX.Element => {
  const { input, options, className, meta } = props
  const { multiple, placeholder, label, fullWidth, accept, disabled } = options

  const imageTypes = 'image/jpeg, image/jpg, image/png, image/gif'
  const videoTypes = 'video/mp4, video/mov'

  const translate = useTranslate()

  const { getRootProps, getInputProps } = useDropzone({
    accept: { accept },
    label: { label },
    onDrop: acceptedFiles => {
      const URL = window.URL || window.webkitURL
      const files = acceptedFiles.map(file => {
        return file.type.includes('video') ? URL.createObjectURL(file) : toBase64(file)
      })
      Promise.all(files).then(images => {
        input.onChange(multiple ? images : images[0])
      })
    },
    ...options,
  })

  const Thumb = ({ preview }: Thumb): JSX.Element => {
    return (
      <Styles.Thumb>
        <Styles.ThumbInner>
          <Styles.Img src={preview} />
        </Styles.ThumbInner>
      </Styles.Thumb>
    )
  }

  const Thumbs = ({ files }: Thumbs): JSX.Element => {
    if (!files || files.length === 0) return <></>
    if (Array.isArray(files)) {
      return (
        <>
          {files.map(file => {
            return <Thumb preview={file} key={file} />
          })}
        </>
      )
    }
    return <Thumb preview={files} />
  }

  return (
    <FormControl
      error={meta && meta.error && meta.touched}
      fullWidth={fullWidth}
      className={className}
      disabled={disabled}
      style={{ display: disabled ? 'none' : 'flex' }}
    >
      <Styles.Container>
        {label && <FormLabel>{label}</FormLabel>}
        <Styles.Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {placeholder && !input.value && placeholder}
          {input.value && !multiple && accept === imageTypes && input.value.includes('image') && (
            <Styles.Img src={input.value} />
          )}
          {input.value && !multiple && accept === videoTypes && !input.value.includes('image') && (
            <Styles.Video src={input.value} controls />
          )}
        </Styles.Dropzone>
        {multiple && (
          <Styles.ThumbsContainer>
            <Thumbs files={input.value} />
          </Styles.ThumbsContainer>
        )}
      </Styles.Container>
      {accept === imageTypes ? (
        <Typography variant='caption' component='div' color='textSecondary'>
          {translate('resources.campaigns.validImgFormat')}
        </Typography>
      ) : (
        <Typography variant='caption' component='div' color='textSecondary'>
          {translate('resources.campaigns.validVideoFormat')}
        </Typography>
      )}
      {meta && meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

const ImageInput = (props: any, options: any): JSX.Element => {
  const { source, ...rest } = props
  return <Field name={source} component={Previews} options={{ ...rest }} />
}
export default ImageInput
