import React from 'react'
import LoginForm from './form'
import Login from '../loginLayout'

const MyLoginPage = (props: any): JSX.Element => (
  <Login {...props}>
    <LoginForm />
  </Login>
)

export default MyLoginPage
