import decodeJwt from 'jwt-decode'

interface LoginProps {
  username: string
  password: string
}

export default {
  login: ({ username, password }: LoginProps) => {
    const request = new Request(process.env.REACT_APP_API_PATH + '/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ token }) => {
        const decodedToken = decodeJwt(token)
        localStorage.setItem('token', token)
        localStorage.setItem('permissions', decodedToken.roles)
      })
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  /*  checkError: error: any => {
        // ...
  },*/
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    if (localStorage.getItem('token')) {
      return role ? Promise.resolve(role) : Promise.reject()
    }
  },
}
